import { useState, useContext, useEffect } from "react";
import Header from "../components/header.js"
import Contact from "../components/contact_popup.js"
import MobileMenu from "../components/mobile_menu.js"
import Button from '../components/button.js';
import Footer from '../components/footer.js';

import parse from 'html-react-parser';

import HomeHeroImg from '../assets/img/home-hero-bkg.png'
import IntegrationSVG from '../assets/img/integration.svg'
import PortalSVG from '../assets/img/man.svg'
import AutomationSVG from '../assets/img/automation.svg'
import AnalyticsSVG from '../assets/img/data.svg'

import { Context } from "../components/globalContext/globalContext.js";

import seo from '../helpers/seo'
import HubspotContactForm from '../components/hubspotForm.js'
import { useOptimizedComponentWillMount } from '../helpers/optimizedComponentWillMount.js'

export default function Home() {
  const globalContext = useContext(Context);
  const { showContact, setShowContact, showMobileMenu, setShowMobileMenu, defaultSEOTitle, defaultMetaDesc, apiDomain, heroMenu } = globalContext;
  const [pageData, setPageData] = useState({});
  const [pageSections, setPageSections] = useState();
  const [hasScrolled, setHasScrolled] = useState(false);

  async function fetchPage() {
    let rawpage = await fetch(`${apiDomain}/pages/home`);
    let mypage = await rawpage.json();

    let sections = mypage.page.sections.sort(function (a, b) {
      return -(b.sort_order - a.sort_order)
    });

    setPageData(mypage.page)
    setPageSections(sections)

    seo({
      "title": mypage.page.seo_title || defaultSEOTitle,
      "metaDescription": mypage.page.search_description || defaultMetaDesc,
    })

  }

  const listenScrollEvent = (e) => {
    if (window.scrollY > 400) {
      if (hasScrolled !== true) {
        setHasScrolled(true)
      }
    } else {
      setHasScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => listenScrollEvent())
  }, []
  )

  useOptimizedComponentWillMount(() => { fetchPage() })

  return (
    <div className="home">

      <Header setShowContact={(val) => { setShowContact(val); setShowMobileMenu(false) }} setShowMobileMenu={(val) => setShowMobileMenu(val)} hasScrolled={hasScrolled} />
      <Contact />
      {(showMobileMenu) ? <MobileMenu setShowContact={(val) => { setShowContact(val); setShowMobileMenu(false) }} setShowMobileMenu={(val) => setShowMobileMenu(val)} /> : null}

      <div className="std-page">


        <div className="hero">
          {console.log(pageData?.hero_img?.file)}
          <img laoding="lazy" alt={pageData?.hero_img?.title} src={(pageData?.hero_img) ? `${pageData?.hero_img.file}` : null} width="1920" height="1080" className="home-hero" />
          <div className="hero-content flex f-col">
            <h1 className="hero-h1" dangerouslySetInnerHTML={{ __html: pageData.hero_heading }} />
            <div className="hero-menu flex f-row a-c j-c">
              {heroMenu?.map((menuItem, ind) => {
                return <h5 key={`HeroMenu${ind}`}><a className="hero-link" href={menuItem.href_url}>{menuItem.name}</a></h5>
              })}
            </div>
          </div>

        </div>
        <div className="short-section flex f-col a-c j-c">
          {pageData.sub_hero_text ? parse(pageData.sub_hero_text) : ""}
          {pageData.sub_hero_cta == true ?
            <Button customClass='h5' marginDrop={true} text="Request A Consultation" onClick={() => setShowContact(true)} />
            :
            null
          }
        </div>

        {pageSections?.map((section, ind) => {

          if (!section.live) {
            return null
          }

          return <div key={`SECTION${ind}`} className={`${(section.is_short) ? "short-section" : "section"} flex f-col a-c j-c`}>

            <h2 className="highlight major-width t-left">{section.heading}</h2>
            <h5 className="major-width t-left ">{section.text ? parse(section.text.replace(/<\/?p[^>]*>/g, "")) : null}</h5>

            {(section?.blurbs ?
              <div className="full-width flex f-row a-c j-c t-m-m f-wrap-w">

                {section.blurbs.map((blurb, ind) => {

                  return <a key={`BLURB${ind}`} className={`blurb flex f-col a-c j-c n-m ${(blurb.href !== null || blurb.url !== "#")}`} href={blurb.href != null ? `${blurb.href}` : `${blurb.url}`}>
                    <img alt={blurb?.image?.title} loading="lazy" src={(blurb?.image) ? `${blurb?.image.file}` : AutomationSVG} className="svg-icon" />
                    <h5>{blurb.text}</h5>
                  </a>
                })
                }
              </div>
              :
              null
            )}


            {(section?.hubspot_form ?
              <HubspotContactForm
                region={section.hubspot_form.region}
                portalId={section.hubspot_form.portalId}
                formId={section.hubspot_form.formId}

              />
              :
              null
            )}


            {(section?.show_cta ?
              <Button customClass='h5' marginDrop={true} text="Request A Consultation" onClick={() => setShowContact(true)} />
              :
              null
            )}





          </div>

        })}

        <div className="section">

        </div>

      </div>

      <Footer />


    </div>
  );
}
