import './App.css';
import './css/mobile.css'

import React, { useState, Fragment} from "react";

import Home from './pages/home.js'
import NotFound from './pages/notfound.js'
import SEOSubscriptions from './pages/seo-subscriptions.js'
import ServiceTemplate from './pages/service-template.js'

import {useOptimizedComponentWillMount} from './helpers/optimizedComponentWillMount.js';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import { Provider } from "./components/globalContext/globalContext.js";
import BlogArchive from './pages/blog_archive.js';

export default function App() {

  const [ pages, setPages ] = useState([]);
  const [apiDomain, setApiDomain] = useState("https://api.poieo-dev.com");

  async function fetchAllPages() {
    let rawpages = await fetch(`${apiDomain}/pages/`);
    let mypages = await rawpages.json();
    setPages(mypages.pages)
  }

  useOptimizedComponentWillMount(() => {fetchAllPages()})


  return (
    <Fragment>{typeof window !== 'undefined' ? 
    <Provider>
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="blog" element={<BlogArchive />} />
          <Route path="seo-subscriptions" element={<SEOSubscriptions />} />

          {pages?.map((val, ind) => {
          //console.log('val.live', val.live, 'val.slug', val.slug)
            if (!val.live || val.slug == "home") {
              return ;
            }
            return <Fragment key={`ROUTE${ind}`} >
              <Route path={val.slug} element={<ServiceTemplate slug={val.slug}/>}/>
              {
                (ind + 1 == pages.length) ? 
                  <Route key="FINALROUTE" path='*' element={<NotFound />}/>
                  :
                  <Route key="FINALROUTE" path='*' element={<NotFound />}/>
              }
              </Fragment>
          })}

          <Route path="/" element={<Home />} />
          {/*<Route path='*' element={<NotFound />}/>*/}
        </Routes>
    </Router>
    </Provider>
  : null } </Fragment>);
}
