
import LeftArrow from './images/left_arrow.png';
import RightArrow from './images/right_arrow.png';
import DownArrow from './images/down_arrow.png';
import UpArrow from './images/down_arrow.png';
import parse from 'html-react-parser';

//UpArrow is not actualy implemented yet - it needs the icon downloaded and added

export default function BlogArchiveSingle(props) {


    return (
        <div onClick={() => props.onClick()} className={`${props.customClass} ${(props.marginDrop) ? 'margin-drop' : ''} blog-archive-single-outer ${(props.arrowPointing === "left" || props.arrowPointing === "down" || props.arrowPointing === "up") ? "weight-right" : ""} ${(props.arrowPointing && props.arrowPointing === "right") ? "weight-left" : ""}`}>
            <div className="blog-archive-single-inner">
                <div className="h5 blog-archive-single-title" style={{color:"black"}}>{props.blog.title}</div>
                <p className="h6" style={{color:"rgb(100,100,100)"}}>{props.blog.hero_heading}</p>
                {/*parse(props.blog.sub_hero_text)*/}
            </div>
        </div>
    );
}
