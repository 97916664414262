import { useState, useContext, useEffect } from "react";
import Header from "../components/header.js"
import Contact from "../components/contact_popup.js"
import MobileMenu from "../components/mobile_menu.js"
import Button from '../components/button.js';
import Footer from '../components/footer.js';

import parse from 'html-react-parser';

import HomeHeroImg from '../assets/img/home-hero-bkg.png'
import IntegrationSVG from '../assets/img/integration.svg'
import PortalSVG from '../assets/img/man.svg'
import AutomationSVG from '../assets/img/automation.svg'
import AnalyticsSVG from '../assets/img/data.svg'

import { Context } from "../components/globalContext/globalContext.js";

import seo from '../helpers/seo'
import HubspotContactForm from '../components/hubspotForm.js'
import { useOptimizedComponentWillMount } from '../helpers/optimizedComponentWillMount.js'
import BlogArchiveSingle from "../components/blogArchiveSingle.js";

export default function BlogArchive() {
  const globalContext = useContext(Context);
  const { showContact, setShowContact, showMobileMenu, setShowMobileMenu, defaultSEOTitle, defaultMetaDesc, apiDomain, heroMenu } = globalContext;
  const [pageData, setPageData] = useState({});
  const [pageSections, setPageSections] = useState();
  const [blogData, setBlogData] = useState({});
  const [hasScrolled, setHasScrolled] = useState(false);

  async function fetchPage() {
    let rawpage = await fetch(`${apiDomain}/pages/blog_archive`);
    let mypage = await rawpage.json();

    let sections = mypage.page.sections.sort(function (a, b) {
      return -(b.sort_order - a.sort_order)
    });

    setPageData(mypage.page)
    setPageSections(sections)

    seo({
      "title": mypage.page.seo_title || defaultSEOTitle,
      "metaDescription": mypage.page.search_description || defaultMetaDesc,
    })

  }

  async function fetchBlogPages() {
    let rawblogs = await fetch(`${apiDomain}/pages/blogpages`);
    let blogs = await rawblogs.json()
    setBlogData(blogs.pages)

  }

  const listenScrollEvent = (e) => {
    if (window.scrollY > 400) {
      if (hasScrolled !== true) {
        setHasScrolled(true)
      }
    } else {
      setHasScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => listenScrollEvent())
  }, [])

  useOptimizedComponentWillMount(() => { fetchPage(); fetchBlogPages() })

  return (
    <div className="home">

      <Header setShowContact={(val) => { setShowContact(val); setShowMobileMenu(false) }} setShowMobileMenu={(val) => setShowMobileMenu(val)} hasScrolled={hasScrolled} />
      <Contact />
      {(showMobileMenu) ? <MobileMenu setShowContact={(val) => { setShowContact(val); setShowMobileMenu(false) }} setShowMobileMenu={(val) => setShowMobileMenu(val)} /> : null}

      <div className="std-page">
        <div className="hero">
          <img laoding="lazy" alt={pageData?.hero_img?.title} src={(pageData?.hero_img) ? `${pageData?.hero_img.file}` : null} width="1920" height="1080" className="home-hero" />
          <div className="hero-content flex f-col">
            <h1 className="hero-h1" dangerouslySetInnerHTML={{ __html: pageData.hero_heading }} />
            {/*<div className="hero-menu flex f-row a-c j-c">
              {heroMenu?.map((menuItem, ind) => {
                return <h5 key={`HeroMenu${ind}`}><a className="hero-link" href={menuItem.href_url}>{menuItem.name}</a></h5>
              })}
            </div>*/}
          </div>

        </div>

        <div className="short-section flex f-col a-c j-c">
          {pageData.sub_hero_text ? parse(pageData.sub_hero_text) : ""}
          {pageData.sub_hero_cta == true ?
            <Button customClass='h5' marginDrop={true} text="Request A Consultation" onClick={() => setShowContact(true)} />
            :
            null
          }
        </div>
        <h2>COMING SOON</h2>

        {pageSections?.map((section, ind) => {

          if (!section.live) {
            return null
          }

          return <div key={`SECTION${ind}`} className={`${(section.is_short) ? "short-section" : "section"} flex f-col a-c j-c`}>



            {(section?.hubspot_form ?
              <HubspotContactForm
                region={section.hubspot_form.region}
                portalId={section.hubspot_form.portalId}
                formId={section.hubspot_form.formId}

              />
              :
              null
            )}
            {(section?.show_cta ?
              <Button customClass='h5' marginDrop={true} text="Request A Consultation" onClick={() => setShowContact(true)} />
              :
              null
            )}
          </div>
        })}
        <div className="section">
        </div>
      </div>
      {console.log(blogData)}
      <div className="blog-archive-outer">
      <div className="blog-archive-inner">
      {blogData.length >= 1 ? (<>{blogData?.map((blog, index) => {
        return (
          

              <BlogArchiveSingle key={blog.translation_key}  blog={blog} onClick={() => { console.log(blog.title) }} />



        )
      })}</>) : null}
                </div>
                </div>
      <Footer />

    </div>

  );
}
