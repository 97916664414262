import React, { useState, useEffect, createContext } from "react";
import {useOptimizedComponentWillMount} from '../../helpers/optimizedComponentWillMount.js'

const Context = createContext();

const Provider = ({ children }) => {

  const [apiDomain, setApiDomain] = useState("https://api.poieo-dev.com");

  const [showContact, setShowContact] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [heroMenu, setHeroMenu] = useState([]);


  async function fetchMenus() {
    let raw_menus = await fetch(`${apiDomain}/pages/menus`);
    let my_menus = await raw_menus.json();

    let hero_menus = my_menus.HERO.sort(function(a,b) {
      return -(b.sort_order - a.sort_order)
    });

    let header_menus = my_menus.HEADER.sort(function(a,b) {
      return -(b.sort_order - a.sort_order)
    });

    setHeaderMenu(header_menus)
    setHeroMenu(hero_menus)
  }

  useOptimizedComponentWillMount(() => {fetchMenus()})  

  const defaultMetaDesc = "";
  const defaultSEOTitle = "Custom Web & Mobile Solutions | Poieo Dev";


  const globalContext = {
    apiDomain,
    showContact,
    setShowContact,
    showMobileMenu,
    setShowMobileMenu,
    defaultMetaDesc,
    defaultSEOTitle,
    headerMenu,
    heroMenu
  };

  return <Context.Provider value={globalContext}>{children}</Context.Provider>;
};

export { Context, Provider };
