export default function Select(props) {

  return (
    <select className={`input column ${(props.value !== "")? "filled-input" : ""}`} name={props.name} value={props.value} onChange={(e) => props.onChange(e.target.value)} >
      <option value='' disabled>{props.name}</option>
      {props.options?.map((val, ind) => {
        return (

          <option disabled={val.disabled} name={val.name} key={`${val.name}-${ind}`}>{val.val}</option>
        )
      })}

    </select>

  );
}
