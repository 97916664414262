import { useState, useEffect, useContext, useRef, Fragment} from "react";
import Logo from './images/logo.webp';
import Button from './button';
import { Context } from "../components/globalContext/globalContext.js";

import DownSVG from '../assets/img/down-chevron.svg'
import DownHighlightSVG from '../assets/img/down-chevron-highlight.svg'

export default function Header(props) {
  const globalContext = useContext(Context);
  const { showContact, setShowContact, showMobileMenu, setShowMobileMenu, headerMenu } = globalContext;

  const [ menuManager, setMenuManager ] = useState({})
  const [ pendingMenu, setPendingMenu ] = useState()
  const [ activatedMenu, setActivatedMenu ] = useState()


  useEffect(() => {
    let tempMenuManager = menuManager
    headerMenu.map((menuItem, ind) => {
      tempMenuManager[`${menuItem.name}-${ind}`] = false;
    })
    setMenuManager(tempMenuManager)

  }, [])

  function updateMenuManager(name, val) {
    if (val == true) {
      setActivatedMenu(name)
    }

    console.log(activatedMenu)
    console.log(menuManager[name])
    setMenuManager(prevState => ({
        ...prevState,
        [name]: val
      }))
  }


  return (
    <div className={`header ${(props.hasScrolled && props.hasScrolled == true)? "header-dark header-dark-fade" : "header-transparent header-transparent-fade"}`}>
      <div className={`col1 header-logo-container pointer`}>
      <a href={"/"}>
        <img width="200px" height="50px" alt="Custom Web and Mobile Development Solutions Poieo Dev Logo" className="header-logo" src={Logo} />
        </a>
      </div>

      <div className="col2 header-menu-container">  
        {
          headerMenu?.map((menuItem, ind) => {
              if (menuItem?.sub_menu) {

                let submenu_items = menuItem.sub_menu.sort(function(a,b) {
                  return -(b.sort_order - a.sort_order)
                });

                return <div key={`HeroMenu${ind}`} onMouseLeave={() => updateMenuManager(`${menuItem.name}-${ind}`, false)}>
                  <h5 className="submenu-h5" onMouseEnter={() => updateMenuManager(`${menuItem.name}-${ind}`, true)} >
                    <a className="link" href={menuItem.href_url}>{menuItem.name} 
                      <img alt="down arrow poieo dev" src={(menuManager[`${menuItem.name}-${ind}`]) ? DownHighlightSVG : DownSVG} width="15" height="15" className="svg-menu-icon" />
                    </a>
                    <div className={`submenu-wrapper ${menuManager[`${menuItem.name}-${ind}`] ? 'show' : 'hide-slow'}`}>
                    <div className={`submenu-inner ${menuManager[`${menuItem.name}-${ind}`] ? 'show' : 'hide-slow'}`} >
                      {
                        menuItem?.sub_menu?.map((subMenuItem, ind) => {
                          return <Fragment key={`HeaderSubMenu${ind}`}>
                            <h5 className="submenu-link">
                              <a className="submenu-link" href={"/"+subMenuItem.href_url}>{subMenuItem.name}
                              </a>
                            </h5>
                            {(ind + 1 < menuItem.sub_menu.length) ? 
                              <div className="divider"></div>
                              :
                              null
                            }
                            </Fragment>
                        })
                      }
                      </div>
                    </div>
                  </h5>



                </div>
              } else {
                return <h5 key={`HeaderMenu${ind}`}><a className="link" href={menuItem.href_url}>{menuItem.name}</a></h5>
              }
              })
        }  
        <Button text="Request a Quote" onClick={()=> setShowContact(true)}/>
      </div>


      <div className="col2 hamburger-menu-container">
        <div onClick={()=> props.setShowMobileMenu(true)} className="hamburger-menu-button">
          <h2 className="menu-icon">☰</h2>
        </div>
      </div>
    </div>
  );
}
