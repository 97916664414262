import { useState, useEffect} from "react";
import Header from "../components/header.js"
import Contact from "../components/contact_popup.js"
import MobileMenu from "../components/mobile_menu.js"

import seo from '../helpers/seo'



export default function NotFound() {

  const [showContact, setShowContact] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    seo({"title": "404 Page Not Found | Custom Web Development | Poieo Dev"})
  }, [])

  return (
    <div>
    <div className="page-outer">
    </div>

    <Header setShowContact={(val)=> setShowContact(val)} setShowMobileMenu={(val)=> setShowMobileMenu(val)} />
    {(showContact)? <Contact setShowContact={(val)=> setShowContact(val)}/> : null}
    {(showMobileMenu)? <MobileMenu setShowContact={(val)=> {setShowContact(val); setShowMobileMenu(false)}} setShowMobileMenu={(val)=> setShowMobileMenu(val)}/> : null}


    <div className="page-content-std">
      <div className="page-inner-std flex-col center-center">
          <h1 className="margin-reduce-header hero-h1">page not found</h1>
          <p className="sub-heading highlight">Looks like we made a mistake and this page no longer exists...</p>
      </div>

      {!(showContact || showMobileMenu)?
        <div className="footer">
          <div className="footer-row">
            <h6 className="highlight pointer footer-margin" onClick={()=> window.open("https://github.com/PoieoDev", "_blank")}>GITHUB</h6>

          </div>
          <div>
            <h6 className="footer-margin">Copyright © Poieo Dev 2024</h6>
          </div>
        </div>
        :
        null
      }

    </div>

    


    </div>
  );
}
