import { useState, useEffect, useRef, useContext} from "react";
//import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

import Button from './button';
import CloseButton from './closeButton';
import Input from './input';
import Select from './select';
import Textarea from './textarea';

import { Context } from "../components/globalContext/globalContext.js";

export default function Contact(props) {

  const globalContext = useContext(Context);
  const { setShowContact, showContact } = globalContext;

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [website, setWebsite] = useState("")
  const [budget, setBudget] = useState("")
  const [project, setProject] = useState("")
  const [details, setDetails] = useState("")
  const [recaptcha, setRecaptcha] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [websiteError, setWebsiteError] = useState(false)
  const [budgetError, setBudgetError] = useState(false)
  const [projectError, setProjectError] = useState(false)
  const [detailsError, setDetailsError] = useState(false)
  const [recaptchaError, setRecaptchaError] = useState(false)
  const [formMessage, setFormMessage] = useState("")

  const recaptchaRef = useRef(null)

  const budgetOptions = [
    {name: "$10k to $30k", val: "$10k to $30k"},
    {name: "$30k to $50K", val: "$30k to $50K"},
    {name: "$50K to $100k", val: "$50K to $100k"},
    {name: "$100K to $250k", val: "$100K to $250k"},
    {name: "$250K to $500k", val: "$250K to $500k"},
    {name: "Greater Than $500k", val: "Greater Than $500k"},

  ]

  const projectOptions = [
    {name: "Website Development", val: "Website Development"},
    {name: "Website Management", val: "Website Management"},
    {name: "Website Design", val: "Website Design"},
    {name: "SEO Retainer", val: "SEO Retainer"},
    {name: "E-Commerce Development", val: "E-Commerce Development"},
    {name: "E-Commerce Management", val: "E-Commerce Management"},
    {name: "SaaS Develpment", val: "SaaS Develpment"},
    {name: "User Portal Develpment", val: "User Portal Develpment"},
    {name: "Mobile App Develpment", val: "Mobile App Develpment"},
    {name: "Data Management & Tooling", val: "Data Management & Tooling"},
    {name: "Manufacturing Automation", val: "Manufacturing Automation"},
    {name: "Internal Tool Development", val: "Internal Tool Development"},
    {name: "ML / AI Development", val: "ML / AI Development"},
    {name: "Integrations", val: "Integrations"},
    {name: "Non-Profit Services", val: "Non-Profit Services"},

  ]

  function escKeyPress(e) {
    if (e.key === "Escape") {
      onClose()
    }
  }

  useEffect(()=> {
    document.addEventListener("keydown", escKeyPress, false);
  }, [])



  function onClose() {
    setName("")
    setEmail("")
    setPhone("")
    setWebsite("")
    setBudget("")
    setProject("")
    setDetails("")
    setShowContact(false)
    setNameError(false)
    setEmailError(false)
    setPhoneError(false)
    setWebsiteError(false)
    setBudgetError(false)
    setProjectError(false)
    setDetailsError(false)
    setRecaptchaError(false)
  }

  function submitAttempt() {
    setNameError(false)
    setEmailError(false)
    setPhoneError(false)
    setWebsiteError(false)
    setBudgetError(false)
    setProjectError(false)
    setDetailsError(false)
    setRecaptchaError(false)

    if (!name) {
      setNameError(true)
    }
    if (!email) {
      setEmailError(true)
    }
    if (!phone) {
      setPhoneError(true)
    }
    if (!website) {
      setWebsiteError(true)
    }
    if (!budget) {
      setBudgetError(true)
    }
    if (!project) {
      setProjectError(true)
    }
    if (!details) {
      setDetailsError(true)
    }
    if (!recaptcha) {
      setRecaptchaError(true)
    }

    if (name && email && phone && website && budget && project && details && recaptcha) {
      handleSubmission()
    }


  }

  function handleSubmission() {

    const serviceID = "service_6d9ar36"
    const templateID = "template_77879b9"
    const templateParams = {
                            "name": name,
                            "email": email,
                            "phone": phone,
                            "location": website,
                            "budget": budget,
                            "project": project,
                            "details": details,
                            }
    const publicKey = "guB2FismGlaj9BI6A"
    emailjs.send(serviceID, templateID, templateParams, publicKey)
    .then(function(response) {
      setFormMessage("We've received your request for a consultation and we'll be in touch within 48 hours")
      setName("")
      setEmail("")
      setPhone("")
      setWebsite("")
      setBudget("")
      setProject("")
      setDetails("")
      recaptchaRef.current.reset()
      window.gtag('config', 'AW-11263247109')
      window.gtag('event', 'conversion', {'send_to': 'AW-11263247109/xTa2CK2S-rwYEIWG3vop'})
    }, function(error) {
       setFormMessage("There was an error. Please try again later")
    });
  }

  return (
    <>
    <div className={`popup-page-outer ${(showContact)? 'show' : 'hide'}`} onClick={() => onClose()}></div>
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>

    <div className={`popup-outer ${(showContact)? 'show' : 'hide'}`}>

    <h2 className="t-left highlight">Request a Consult</h2>
    {(formMessage)? <h6 className="form-message">{formMessage}</h6>: null}

    <div className="close-button-loc">
      <CloseButton onClick={() => onClose()} />
    </div>

    <div className="contact-form-layout">
      <Input placeholder={(nameError)? "Name is required": "First and Last Name"} type="text" value={name} name="name" onChange={(e) => setName(e)} />
      <Input placeholder={(emailError)? "Email is required": "Email"} type="email" value={email} name="email" onChange={(e) => setEmail(e)} />
    </div>

    <div className="contact-form-layout">
      <Input placeholder={(phoneError)? "Phone is required": "Phone #"} type="tel" value={phone} name="phone " onChange={(e) => setPhone(e)} />
      <Input placeholder={(websiteError)? "Website URL is required": "Website"} value={website} name="website" onChange={(e) => setWebsite(e)} />
    </div>

    <div className="contact-form-layout">
      <Select value={budget} name={(budgetError)? "Project Budget is required": "Project Budget"} onChange={(e) => setBudget(e)} options={budgetOptions}/>
      <Select value={project} name={(projectError)? "Requested Service is required": "Requested Service"} onChange={(e) => setProject(e)} options={projectOptions}/>
    </div>

    <div className="contact-form-layout">
      <Textarea placeholder={(detailsError)? "Project description is required": "Description of project"} value={details} name="details" onChange={(e) => setDetails(e)} type="text" rows={3}/>
    </div>

    {/*<div className="contact-form-layout column recaptcha">
    {(recaptchaError)? <h6 className="error-text">Recaptcha is required</h6>: null}
    <ReCAPTCHA
      sitekey="6Lc3UVshAAAAAJI3gnDsNO4xVFj74N0cY39GTLIG"
      onChange={(e) => {setRecaptcha(e); setRecaptchaError(false)}}
      theme="dark"
      ref={recaptchaRef}
      />
    </div>

    <div className={(recaptchaError)? "separater-error" : "separater"} />*/}

    <div className="contact-form-layout">
      <div className="column" />
      <div className="column">
        <Button text="Request Quote" onClick={()=> submitAttempt()}/>
      </div>
    </div>

    </div>
    </>
  );
}
