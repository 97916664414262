import CloseButton from './closeButton';
import Button from './button';
import { useEffect, useState, useContext, Fragment } from 'react';
import { Context } from "../components/globalContext/globalContext.js";
import DownSVG from '../assets/img/down-chevron.svg'
import DownHighlightSVG from '../assets/img/down-chevron-highlight.svg'


export default function MobileMenu(props) {
  const globalContext = useContext(Context);
  const { showContact, setShowContact, showMobileMenu, setShowMobileMenu, headerMenu } = globalContext;
  const [ menuManager, setMenuManager ] = useState({})

  useEffect(() => {
    let tempMenuManager = menuManager
    headerMenu.map((menuItem, ind) => {
      tempMenuManager[`${menuItem.name}-${ind}`] = false;
    })
    setMenuManager(tempMenuManager)

  }, [])

  function updateMenuManager(name) {

    setMenuManager(prevState => ({
        ...prevState,
        [name]: !menuManager[name]
      }))
  }

  return (
    <div className="popup-page-outer mobile-menu">
    <div className="close-button-loc">
      <CloseButton onClick={() => props.setShowMobileMenu(false)} />
    </div>
            {
          headerMenu?.map((menuItem, ind) => {
              if (menuItem?.sub_menu) {

                let submenu_items = menuItem.sub_menu.sort(function(a,b) {
                  return -(b.sort_order - a.sort_order)
                });

                return <div key={`MobileMenu${ind}`}>
                  <h5 className={`link mobile-h5 ${menuManager[`${menuItem.name}-${ind}`] ? "highlight" : "white-text"}`} onClick={() => updateMenuManager(`${menuItem.name}-${ind}`)} >
                    <a className={`${menuManager[`${menuItem.name}-${ind}`] ? "highlight" : "white-text"}`}href={menuItem.href_url}>{menuItem.name} 
                      <img alt="down arrow poieo dev" src={(menuManager[`${menuItem.name}-${ind}`]) ? DownHighlightSVG : DownSVG} width="15" height="15" className="svg-menu-icon" />
                    </a>
                    <div className={`mobile-submenu-wrapper ${menuManager[`${menuItem.name}-${ind}`] ? 'show' : 'hide'}`}>
                    <div className="mobile-submenu-inner" >
                      {
                        menuItem?.sub_menu?.map((subMenuItem, ind) => {
                          return <Fragment key={`HeaderSubMenu${ind}`}>
                            <h5 className="link mobile-h5" onClick={() => {props.setShowMobileMenu(false)}}>
                              <a href={"/"+subMenuItem.href_url}>{subMenuItem.name}
                              </a>
                            </h5>
                            <div className="divider"></div>
                            {(ind + 1 < subMenuItem.length) ? 
                              <div className="divider"></div>
                              :
                              null
                            }
                            </Fragment>
                        })
                      }
                      </div>
                    </div>
                  </h5>



                </div>
              } else {
                return <h5 className="link mobile-h5" key={`MobileMenu${ind}`} onClick={() => {props.setShowMobileMenu(false)}}><a href={menuItem.href_url}>{menuItem.name}</a></h5>
              }
              })
        } 

      <h5 className="link mobile-h5 mobile-link-bottom-margin" onClick={() => {props.setShowMobileMenu(false)}}><a href="/">Home</a></h5>
          
      <Button text="Request a Consult" onClick={()=> props.setShowContact(true)}/>
    </div>
  );
}
