export default function seo(data = {}) {
  data.title = data.title
  data.metaDescription = data.metaDescription

  document.title = data.title;
  document.querySelector('meta[property="og:title"]').setAttribute('content', data.title);
  document.querySelector('meta[property="twitter:title"]').setAttribute('content', data.title);
  document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  document.querySelector('meta[property="og:description"]').setAttribute('content', data.metaDescription);
  document.querySelector('meta[property="twitter:description"]').setAttribute('content', data.metaDescription);

}