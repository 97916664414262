import { useState, useEffect} from "react";
import Header from "../components/header.js"
import Contact from "../components/contact_popup.js"
import MobileMenu from "../components/mobile_menu.js"
import Footer from "../components/footer.js";
import Button from '../components/button';

import seo from '../helpers/seo'

export default function SEOSubscriptions() {

  const [showContact, setShowContact] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    seo({"title": "SEO Subscriptions| Custom Web Development | Poieo Dev",
          "metaDescription": "Poieo Dev is a talented web development company with skills including bespoke web development, custom algorithms, e-commerce growth, and machine learning."})
  }, [])

  return (
    <div>
    <div className="page-outer">
    </div>

    <Header setShowContact={(val)=> setShowContact(val)} setShowMobileMenu={(val)=> setShowMobileMenu(val)} />
    {(showContact)? <Contact setShowContact={(val)=> setShowContact(val)}/> : null}
    {(showMobileMenu)? <MobileMenu setShowContact={(val)=> {setShowContact(val); setShowMobileMenu(false)}} setShowMobileMenu={(val)=> setShowMobileMenu(val)}/> : null}


    <div className="page-content-std">
    <div className="page-inner-std jc">

        <h1 className="margin-reduce-header margin-bottom hero-h1" style={{textAlign: "center"}}>seo plans</h1>

        <stripe-pricing-table pricing-table-id="prctbl_1OXE4KFBOi9gOtRDHAfheYQY"
          publishable-key="pk_live_ZRIJG1m9TbDjiZHakfSvCcT1006p6TJE4j">
        </stripe-pricing-table>
    </div>





    <Footer />

  </div>

    


    </div>
  );
}
