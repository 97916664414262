
import LeftArrow from './images/left_arrow.png';
import RightArrow from './images/right_arrow.png';
import DownArrow from './images/down_arrow.png';
import UpArrow from './images/down_arrow.png';

//UpArrow is not actualy implemented yet - it needs the icon downloaded and added

export default function Button(props) {


  return (
    <div onClick={()=> props.onClick()} className={`${props.customClass} ${(props.marginDrop)? 'margin-drop' : ''} button-outer ${(props.arrowPointing === "left" || props.arrowPointing === "down" || props.arrowPointing === "up")? "weight-right" : ""} ${(props.arrowPointing && props.arrowPointing === "right")? "weight-left" : ""}`}>
      <div className="button-inner">
      {(props.arrowPointing && (props.arrowPointing === "left" || props.arrowPointing === "down" || props.arrowPointing === "up")) ?
        <img alt="world class website development poieo dev arrow button" src={(props.arrowPointing === "left")? LeftArrow : (props.arrowPointing === "down")? DownArrow : UpArrow} className="button-arrow-left" />
        :
        null
      }
      <p className="black-fg h6">{props.text}</p>

      {(props.arrowPointing && props.arrowPointing === "right") ?
        <img alt="world class website development poieo dev arrow button" src={RightArrow} className="button-arrow-right" />
        :
        null
      }
      </div>
    </div>
  );
}
