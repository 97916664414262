

//UpArrow is not actualy implemented yet - it needs the icon downloaded and added

export default function CloseButton(props) {


  return (
    <div onClick={()=> props.onClick()} className="close-button-outer">

      <h5>X</h5>

    </div>
  );
}
