import React, { useEffect } from 'react'

const HubspotContactForm = (props) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: '#hubspotForm',
          ...props
        })
      }
    })
  }, [])

  return <div className="hubspotForm" id="hubspotForm"></div>
}

export default HubspotContactForm