import { useState, useEffect, useContext } from "react";
import Logo from './images/logo.webp';
import Button from './button';
import { Context } from "../components/globalContext/globalContext.js";


export default function Footer(props) {
  const globalContext = useContext(Context);
  const { showContact, showMobileMenu, headerMenu } = globalContext;

  useEffect(() => {
    console.log(headerMenu)
  }, [headerMenu])


  return (
    <>
      {!(showContact || showMobileMenu) ?
        <div className="footer-outer">
          <h3 className="footer-heading highlight"><strong>Custom. Excellence. Vision.</strong></h3>
          <div className="footer-inner">
            <div className="footer-row footer-menu">
              {headerMenu?.map((item, index) => {
                console.log(item)
                return (
                  <div className="footer-column footer-menu">
                    <h5 className="pointer footer-margin footer-menu-heading">{item.name == "Blog" ? (<a href={item.href_url} target="_blank">{item.name}</a>) : item.name }</h5>
                    <>{item?.sub_menu?.map((subItem, subIndex) => {
                      return (
                        <h6 className="pointer footer-margin footer-menu-item"><a href={"/" + subItem.href_url} target="_blank">{subItem.name}</a></h6>
                      )
                    })}</>
                  </div>
                )
              })}


            </div>



              

          </div>
          <div className="footer-row footer-copyright">
          <h6 className="footer-margin" style={{alignSelf: 'center', justifySelf: 'center'}}>Copyright © Poieo Dev 2024</h6>
          <h6 className="pointer footer-margin"><a href="https://www.instagram.com/poieo_dev/" target="_blank">Instagram</a></h6>
          <h6 className="pointer footer-margin"><a href="https://www.tiktok.com/@poieodev" target="_blank">TikTok</a></h6>
          </div>
          
        </div>
        :
        null
      }
    </>
  );
}
